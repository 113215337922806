import Image from 'next/image';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TrackButton } from 'components/TrackButton';
import { RichText } from 'components/ContentfulContent/RichText/RichText';

import { TrackingEvent } from 'lib/tracking/events';

import type { Document } from '@contentful/rich-text-types';

const AdvantagesComponentWrapper = styled(Container)`
  padding-top: ${(props) => props.theme.spacing(5)};
  padding-bottom: ${(props) => props.theme.spacing(8)};
  display: grid;
  grid-template-columns: 1fr;

  ${(props) => props.theme.breakpoints.up('lg')} {
    grid-template-columns: 40% 1fr;
    padding-bottom: 0;
  }

  Button {
    margin-top: ${(props) => props.theme.spacing(6)};
    height: 48px;
    width: 100%;

    ${(props) => props.theme.breakpoints.up('lg')} {
      flex-direction: row;
      margin-bottom: 0;
      width: 40%;
      font-weight: 500;
    }
  }

  h3 {
    font-weight: 700;
  }
`;

const ImageWrapper = styled('div')`
  position: relative;
  margin-right: 56px;
  width: 404px;
  max-width: 404px;
  flex-shrink: 0;

  ${(props) => props.theme.breakpoints.down('lg')} {
    width: 100%;
    max-height: 350px;
    min-height: 250px;
    overflow: hidden;
    margin: 0 auto;
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing(3)};

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-top: 0;
  }
`;

const ItemWrapper = styled('ul')`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;

  ${(props) => props.theme.breakpoints.up('lg')} {
    grid-template-columns: 1fr 1fr;
    flex-direction: row;
  }
`;

const Item = styled('li')`
  display: flex;
  flex-direction: row;
  width: 100%;
  list-style: none;
  margin-top: 32px;
  flex: 0 50%;

  > * {
    min-width: 40px;
  }
`;

const TextWrapper = styled('div')`
  margin: 0 0 0 8px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 0 40px 0 8px;
  }
`;

export type AdvantagesProps = any;

export default function Advantages(a: AdvantagesProps) {
  const { headline, points, phone } = a;
  const phoneFile = phone.fields.file;
  const plainMobileImgUrl = phoneFile!.url! as string;
  const imageMobileUrl = plainMobileImgUrl.startsWith('//')
    ? `https:${plainMobileImgUrl}`
    : plainMobileImgUrl;

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <AdvantagesComponentWrapper maxWidth="lg">
      {imageMobileUrl && (
        <ImageWrapper>
          <Image
            src={imageMobileUrl}
            alt="Symbolbild Liste der Angebote"
            fill={true}
            style={{ objectFit: 'cover', objectPosition: '50% 0' }}
            priority={false}
          />
        </ImageWrapper>
      )}
      <ContentWrapper>
        {headline && <Typography variant="h2">{headline}</Typography>}
        {points && (
          <ItemWrapper>
            {points.map((point: any) => {
              const { icon, text } = point.fields as any;
              const iconFile = icon?.fields.file;
              const plainImgUrl = iconFile?.url as string;
              const iconUrl = plainImgUrl?.startsWith('//')
                ? `https:${plainImgUrl}`
                : plainImgUrl;
              const altText =
                icon?.fields.description || iconFile?.fileName || '';

              return (
                <Item key={point.sys.id}>
                  {iconUrl && (
                    <Image
                      src={iconUrl}
                      alt={altText as string}
                      height={50}
                      width={50}
                      priority={false}
                    />
                  )}
                  <TextWrapper>
                    <RichText document={text as Document} />
                  </TextWrapper>
                </Item>
              );
            })}
          </ItemWrapper>
        )}
        <TrackButton
          variant="contained"
          onClick={handleClick}
          trackingEvent={TrackingEvent.ADVANTAGES_BUTTON_ONINTERACT}
        >
          Jetzt Kreditvergleich starten
        </TrackButton>
      </ContentWrapper>
    </AdvantagesComponentWrapper>
  );
}
